import React, { ReactElement, useEffect, useRef, useState } from "react";

import "./team_lead_suggest_team_member_page.less";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { navigate } from "@reach/router";
import { Headline } from "@src/components";
import { AllocationDetailsTable } from "@src/components/allocation_details_table";
import { ConfirmationModal } from "@src/components/modals/confirmation_modal";
import { NoteForm } from "@src/components/note/note_form";
import { NoteHistory } from "@src/components/note/note_history";
import { NoteModal } from "@src/components/note/note_modal";
import { REQUEST_STATUS, ROLES, ROUTES } from "@src/constants";
import { Events } from "@src/events";
import { SuggestWorkload } from "@src/features/staffing_requests/components/suggest_workload";
import { TeamMemberCapacities } from "@src/features/staffing_requests/components/team_member_capacities";
import transformYearMonth from "@src/features/staffing_requests/utils/prepare_allocation_data_utils";
import CheckUserRoleService from "@src/services/checkUserRole";
import { useLazyGetTeamLeadsQuery } from "@src/services/slices/employeesSlice";
import {
  useAcceptUpdatedRequestMutation,
  useForwardRequestMutation,
  useLazyGetTeamLeadRequestDetailsQuery,
  useLazyGetTeamMembersQuery,
  useProposeTeamMemberMutation,
  useRevokeUpdatedRequestMutation,
  useTakeBackProposalMutation,
  useTeamLeadDeclineRequestMutation,
  useUpdateInRecruitmentMutation,
} from "@src/services/slices/teamLeadsApi";
import { Employee, RequestOverviewDetails } from "@src/types";
import { RequestIdTransformation } from "@src/utils/helper";
import { userUtils } from "@src/utils/login_utils";
import {
  Alert,
  Button,
  Col,
  message,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import PubSub from "pubsub-js";
import Cookies from "universal-cookie";

const { Title, Paragraph } = Typography;

export interface TeamLeadSuggestTeamMemberPageProps {
  id: string;
}

const TeamLeadSuggestTeamMemberPage: React.FC<
  TeamLeadSuggestTeamMemberPageProps
> = ({ id }) => {
  // boolean values
  const [declineWithCommentVisible, setDeclineWithCommentVisible] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [inRecruitmentChecked, setInRecruitmentChecked] =
    useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [inRecruitmentModalVisible, setInRecruitmentModalVisible] =
    useState<boolean>(false);
  const [proposeTeamMemberModalVisible, setProposeTeamMemberModalVisible] =
    useState<boolean>(false);
  const [proposeVacancyModalVisible, setProposeVacancyModalVisible] =
    useState<boolean>(false);
  const [forwardRequestModalVisible, setForwardRequestModalVisible] =
    useState<boolean>(false);
  const [transferRequestModalVisible, setTransferRequestModalVisible] =
    useState<boolean>(false);
  const [inRecruitment, setInRecruitment] = useState<boolean>(false);
  const [isOutdated, setIsOutdated] = useState<boolean>(false);
  const [suggestWorkloadVisible, setSuggestWorkloadVisible] =
    useState<boolean>(false);
  const [suggestWorkloadVacancyVisible, setSuggestWorkloadVacancyVisible] =
    useState<boolean>(false);
  const [isLoggedInUserDeputy, setIsLoggedInUserDeputy] =
    useState<boolean>(false);
  const [isDeclineForwardedVisible, setIsDeclineForwardedVisible] =
    useState<boolean>(false);

  //string
  const [selectedTeamMemberFullName, setSelectedTeamMemberFullName] =
    useState<string>("");
  const [selectedForwardTeamLeadFullName, setSelectedForwardTeamLeadFullName] =
    useState<string>("");
  const [selectedVacancyName, setSelectedVacancyName] = useState<string>("");

  const [teamMembers, setTeamMembers] = useState<Employee[]>([]);
  const [vacancies, setVacancies] = useState<Employee[]>([]);
  const [teamLeads, setTeamLeads] = useState<Employee[]>([]);
  const [roleRequestDetails, setRoleRequestDetails] = useState<any>({});

  const [allocationDetailsData, setAllocationDetailsData] = useState({
    transformedAllocations: [],
    originalAllocations: [],
    proposedTransformedAllocations: [],
    transformedEditedAllocations: [],
  });
  const [selectedTeamLeadId, setSelectedTeamLeadId] = useState<number>();
  const [selectedMemberId, setSelectedMemberId] = useState<number>();
  const [selectedVacancyId, setSelectedVacancyId] = useState<number>();

  const cookies = new Cookies();
  const childRef: any = useRef();

  const columns = [
    {
      title: "Standard role",
      dataIndex: "genericRole",
      key: "genericRole",
    },
    {
      title: "Specific role",
      dataIndex: "specificRole",
      key: "specificRole",
      render: (text: string, record) => {
        return getColumnRenderer(text, record, "specificRole");
      },
    },
    {
      title: "Project",
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: "Phase",
      dataIndex: "projectPhase",
      key: "projectPhase",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
  ];

  const employeeData = userUtils.getEmployeeData();

  const [proposeTeamMember] = useProposeTeamMemberMutation();
  const [takeBackProposal] = useTakeBackProposalMutation();
  const [updateInRecruitment] = useUpdateInRecruitmentMutation();
  const [forwardRequest] = useForwardRequestMutation();
  const [acceptUpdatedRequest] = useAcceptUpdatedRequestMutation();
  const [revokeUpdatedRequest] = useRevokeUpdatedRequestMutation();

  const [fetchTeamLeads] = useLazyGetTeamLeadsQuery();
  const [fetchTeamMembers] = useLazyGetTeamMembersQuery();
  const [declineRequest] = useTeamLeadDeclineRequestMutation();
  const [fetchTeamLeadRequestDetails] = useLazyGetTeamLeadRequestDetailsQuery();

  const markForRecruitmentSection: ReactElement = (
    <Paragraph className="suggest-team-member top-margin recruitment-decline-area">
      <div className="recruitment-section">
        <label data-testid="markInRecruitmentLabel">
          Mark as "In recruitment" for all viewers:
        </label>
        <div className="switch">
          In recruitment{" "}
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            data-testid="InRecruitment"
            checked={inRecruitment}
            disabled={isOutdated}
            onChange={(v) => {
              setInRecruitmentChecked(v);
              setInRecruitmentModalVisible(true);
            }}
          />
        </div>
        <NoteModal
          action={saveOnInRecruitmentChange}
          setModalVisible={setInRecruitmentModalVisible}
          modalVisible={inRecruitmentModalVisible}
          requestId={parseInt(id)}
          isProjectLead={false}
          isNoteMandatory={false}
          modalMsg={{
            action: "Save",
            title: "Set employee in recruitment",
            msg: "Are you sure you want to set this employee in recruitment?",
            leaveCommentFor: switchNameAndSurname(
              roleRequestDetails.projectLead
            ),
            size: "large",
          }}
        />
      </div>

      <div className="decline-with-comment-section">
        <label>
          The request is not plausible? Decline and propose a different workload
        </label>
        <Button
          type="primary"
          danger
          onClick={() => setDeclineWithCommentVisible(true)}
          data-testid="decline-with-comment-button"
        >
          Decline with comment
        </Button>
      </div>
    </Paragraph>
  );

  const declineForwardedRequestSection: ReactElement = (
    <>
      <Paragraph className="suggest-team-member top-margin">
        <div className="btn-propose">
          <Button onClick={() => setIsDeclineForwardedVisible(true)}>
            Decline Request
          </Button>
        </div>
      </Paragraph>
      <NoteModal
        action={declineForwardedRequest}
        setModalVisible={setIsDeclineForwardedVisible}
        modalVisible={isDeclineForwardedVisible}
        requestId={parseInt(id)}
        isProjectLead={false}
        isNoteMandatory={false}
        modalMsg={{
          action: "Save",
          title: "Decline forwarded request",
          msg: (
            <span>
              Are you sure you want to decline the forwarded request? The
              request will be send back to
              <b>
                {switchNameAndSurname(
                  roleRequestDetails.primaryTeamLeadEmployeeName
                )}
              </b>
            </span>
          ),
          size: "large",
        }}
      />
    </>
  );

  const declineRequestWithCommentSection: ReactElement = (
    <SuggestWorkload
      id={parseInt(id)}
      selectedMemberId={selectedMemberId}
      setModalVisible={setDeclineWithCommentVisible}
      modalVisible={declineWithCommentVisible}
      requestedAllocations={allocationDetailsData}
      selectedMemberCapacity={null}
      modalMsg={{
        action: "Save",
        title: "Are you sure you want to decline this request?",
        msg: null,
        leaveCommentFor: switchNameAndSurname(roleRequestDetails.projectLead),
        size: "large",
      }}
      variant="decline"
    />
  );

  const messageWhenNotForwarded: ReactElement =
    roleRequestDetails.assignedTeamMember ? (
      <p>
        You have already proposed{" "}
        <i>'{roleRequestDetails.assignedTeamMember}'</i>
      </p>
    ) : (
      <p>
        You marked this request <b>in recruitment</b>
      </p>
    );

  const initialResponsePrompt: ReactElement = (
    <Paragraph className="suggest-team-member top-margin">
      <Title level={4}>Does your initial response still apply?</Title>
      {roleRequestDetails.status === REQUEST_STATUS.IN_PROGRESS ? (
        <p>You forwarded this request to another team lead.</p>
      ) : (
        messageWhenNotForwarded
      )}
      <div className="btn-propose">
        <Row>
          <Col sm={12}>
            <Button
              onClick={async () => {
                if (
                  roleRequestDetails.status === REQUEST_STATUS.IN_RECRUITMENT ||
                  roleRequestDetails.status === REQUEST_STATUS.PROPOSED ||
                  roleRequestDetails.status === REQUEST_STATUS.CONFIRMED
                ) {
                  await setProposedRequestToRequested();
                } else {
                  await onAcceptUpdatedRequest();
                }
              }}
            >
              No, I need to change it
            </Button>
            <ConfirmationModal
              action={setProposedRequestToRequested}
              modalVisible={transferRequestModalVisible}
              setModalVisible={setTransferRequestModalVisible}
              modalMessage={{
                title: (
                  <p>
                    This request will be transferred to
                    {switchNameAndSurname(
                      roleRequestDetails.primaryTeamLeadEmployeeName
                    )}
                  </p>
                ),
                button: "Confirm",
                warning: "",
              }}
            />
          </Col>
          <Col sm={12}>
            <Button type="primary" onClick={() => onAcceptUpdatedRequest(true)}>
              Yes, the response still applies
            </Button>
          </Col>
        </Row>
      </div>
    </Paragraph>
  );

  const displayTeamLeadActions: ReactElement =
    !isRequestHandedOverToSecondaryTL() &&
    (isRequestForwardedToAnother() ||
      roleRequestDetails.status === REQUEST_STATUS.PROPOSED ||
      roleRequestDetails.status === REQUEST_STATUS.CONFIRMED) ? (
      <></>
    ) : (
      <>
        {!isOutdated && (
          <>
            <div data-testid="furtherRequestActionsSection">
              <Paragraph>
                <div className="suggest-team-member-title top-margin">
                  <h2
                    data-testid="matchRequestTitle"
                    className="suggest-team-member-headline"
                  >
                    {roleRequestDetails.isDeclined
                      ? "Who else could match this request?"
                      : "Who can match this request?"}
                  </h2>
                  <label>
                    Select a team member which could cover the project request:
                  </label>
                  {roleRequestDetails.isDeclined && (
                    <p className="decline-message">
                      If you don’t know why &nbsp;
                      {roleRequestDetails.assignedTeamMember ? (
                        <span className="declined-member">
                          “{roleRequestDetails.assignedTeamMember}”{" "}
                        </span>
                      ) : (
                        "your request "
                      )}
                      has been declined, please contact the requester (
                      {roleRequestDetails.projectLead}).
                    </p>
                  )}
                </div>
                <div className="suggest-team-member">
                  <Select
                    disabled={inRecruitment || isOutdated}
                    showSearch
                    allowClear
                    onChange={onSelectedMemberChange}
                    data-testid="suggestTeamMemberSelection"
                    placeholder="Select team member"
                  >
                    {teamMembers?.length
                      ? teamMembers.map((member) => {
                          return (
                            <Select.Option
                              data-testid={member.employeeId}
                              key={member?.employeeId}
                              data-type="number"
                              value={member.fullName}
                            >
                              {member.fullName}
                            </Select.Option>
                          );
                        })
                      : null}
                  </Select>
                  <div className="btn-propose">
                    <Button
                      className="btn-suggest-workload"
                      data-testid="suggestDifferentWorkloadBtn"
                      disabled={
                        !selectedMemberId || inRecruitment || isOutdated
                      }
                      title={
                        inRecruitment
                          ? "Recruitment is in progress, uncheck 'In recruitment' switch to assign a team member"
                          : ""
                      }
                      onClick={() => {
                        setSuggestWorkloadVisible(true);
                      }}
                    >
                      Suggest different workload
                    </Button>

                    <Button
                      className="btn-suggest"
                      data-testid="proposeSelectedTeamMemberBtn"
                      type="primary"
                      disabled={
                        !selectedMemberId || inRecruitment || isOutdated
                      }
                      title={
                        inRecruitment
                          ? "Recruitment is in progress, uncheck 'In recruitment' switch to assign a team member"
                          : ""
                      }
                      onClick={() => setProposeTeamMemberModalVisible(true)}
                    >
                      Propose selected team member
                    </Button>

                    <NoteModal
                      action={onProposeTeamMember}
                      setModalVisible={setProposeTeamMemberModalVisible}
                      modalVisible={proposeTeamMemberModalVisible}
                      requestId={parseInt(id)}
                      isProjectLead={false}
                      isNoteMandatory={false}
                      modalMsg={{
                        action: "Save",
                        title: "Propose employee",
                        msg: (
                          <span>
                            Are you sure you want to propose{" "}
                            <b>
                              {switchNameAndSurname(selectedTeamMemberFullName)}
                            </b>
                            ?
                          </span>
                        ),
                        leaveCommentFor: switchNameAndSurname(
                          roleRequestDetails.projectLead
                        ),
                        size: "large",
                      }}
                    />

                    {suggestWorkloadVisible && (
                      <SuggestWorkload
                        id={parseInt(id)}
                        modalVisible={suggestWorkloadVisible}
                        selectedMemberId={selectedMemberId}
                        selectedMemberCapacity={childRef?.current?.getProposedMemberCapacity(
                          selectedMemberId
                        )}
                        setModalVisible={setSuggestWorkloadVisible}
                        requestedAllocations={allocationDetailsData}
                        modalMsg={{
                          action: "Save",
                          title: "",
                          msg: "",
                          btnStatus: false,
                          size: "large",
                        }}
                        variant="propose"
                      />
                    )}
                  </div>
                </div>
              </Paragraph>
              <Paragraph className="suggest-team-member top-margin">
                <label>
                  Select a vacancy which could cover the project request:
                </label>
                <Select
                  disabled={inRecruitment || isOutdated}
                  showSearch
                  allowClear
                  onChange={onSelectedVacancyChange}
                  placeholder="Select vacancy"
                >
                  {vacancies && vacancies.length
                    ? vacancies.map((member) => {
                        return (
                          <Select.Option
                            data-testid={member.employeeId}
                            key={member?.employeeId}
                            data-type="number"
                            value={member.fullName}
                          >
                            {member.positionStartDate
                              ? moment(member.positionStartDate).format(
                                  "DD/MM/YYYY"
                                ) + " - "
                              : ""}
                            {member.positionEndDate
                              ? moment(member.positionEndDate).format(
                                  "DD/MM/YYYY"
                                ) + " - "
                              : ""}
                            {member.positionText} ({member.deskId}) -{" "}
                            {member.totalAvailablePercentage}% -{" "}
                            {member.contractType
                              ? member.contractType
                              : "no contract type"}
                          </Select.Option>
                        );
                      })
                    : null}
                </Select>
                <div className="btn-propose">
                  <Button
                    className="btn-suggest-workload"
                    data-testid="suggestDifferentWorkloadBtn"
                    disabled={!selectedVacancyId || inRecruitment || isOutdated}
                    title={
                      inRecruitment
                        ? "Recruitment is in progress, uncheck 'In recruitment' switch to assign a vacancy"
                        : ""
                    }
                    onClick={() => {
                      setSuggestWorkloadVacancyVisible(true);
                    }}
                  >
                    Suggest different workload
                  </Button>

                  <Button
                    className="btn-suggest"
                    data-testid="proposeSelectedVacancyBtn"
                    type="primary"
                    disabled={!selectedVacancyId || inRecruitment || isOutdated}
                    title={
                      inRecruitment
                        ? "Recruitment is in progress, uncheck 'In recruitment' switch to assign a vacancy"
                        : ""
                    }
                    onClick={() => setProposeVacancyModalVisible(true)}
                  >
                    Propose selected vacancy
                  </Button>
                  <NoteModal
                    action={onProposeVacancy}
                    setModalVisible={setProposeVacancyModalVisible}
                    modalVisible={proposeVacancyModalVisible}
                    requestId={parseInt(id)}
                    isProjectLead={false}
                    isNoteMandatory={false}
                    modalMsg={{
                      action: "Save",
                      title: "Propose vacancy",
                      msg: (
                        <span>
                          Are you sure you want to propose{" "}
                          <b>{switchNameAndSurname(selectedVacancyName)}</b>?
                        </span>
                      ),
                      leaveCommentFor: switchNameAndSurname(
                        roleRequestDetails.projectLead
                      ),
                      size: "large",
                    }}
                  />

                  {suggestWorkloadVacancyVisible && (
                    <SuggestWorkload
                      id={parseInt(id)}
                      modalVisible={suggestWorkloadVacancyVisible}
                      selectedMemberId={selectedVacancyId}
                      selectedMemberCapacity={childRef?.current?.getProposedMemberCapacity(
                        selectedVacancyId
                      )}
                      setModalVisible={setSuggestWorkloadVacancyVisible}
                      requestedAllocations={allocationDetailsData}
                      modalMsg={{
                        action: "Save",
                        title: "",
                        msg: "",
                        btnStatus: false,
                        size: "large",
                      }}
                      variant="propose"
                    />
                  )}
                </div>
              </Paragraph>
              <Paragraph className="suggest-team-member top-margin">
                <h2
                  data-testid="forwardRequestTitle"
                  className="suggest-team-member-headline"
                >
                  Don't have anyone on your team?
                </h2>
                <label>Forward request to another team lead:</label>
                <Select
                  disabled={inRecruitment || isOutdated}
                  showSearch
                  allowClear
                  onChange={onSelectedTeamLeadChange}
                  data-testid="teamLead"
                  placeholder="Select team lead"
                >
                  {teamLeads?.length
                    ? teamLeads.map((member) => {
                        return (
                          <Select.Option
                            data-testid={member.employeeId}
                            key={member.employeeId}
                            value={member.fullName}
                          >
                            {member.fullName}
                          </Select.Option>
                        );
                      })
                    : null}
                </Select>
                <div className="btn-propose">
                  <Button
                    disabled={
                      !selectedTeamLeadId || inRecruitment || isOutdated
                    }
                    data-testid="forwardRequestBtn"
                    title={
                      inRecruitment
                        ? "Recruitment is in progress, uncheck 'In recruitment' switch to forward the request"
                        : ""
                    }
                    onClick={() => setForwardRequestModalVisible(true)}
                  >
                    Forward request
                  </Button>
                  <NoteModal
                    action={forwardRequestToTeamLead}
                    setModalVisible={setForwardRequestModalVisible}
                    modalVisible={forwardRequestModalVisible}
                    requestId={parseInt(id)}
                    isProjectLead={false}
                    isNoteMandatory={false}
                    modalMsg={{
                      action: "Forward request",
                      title: "Forward request",
                      msg: (
                        <span>
                          Are you sure you want to forward the request to{" "}
                          <b>
                            {switchNameAndSurname(
                              selectedForwardTeamLeadFullName
                            )}
                          </b>
                          ?
                        </span>
                      ),
                      leaveCommentFor: switchNameAndSurname(
                        roleRequestDetails.projectLead
                      ),
                      size: "large",
                    }}
                  />
                </div>
              </Paragraph>
              <Paragraph>
                <div className="orSeperator">
                  <hr />
                  <span>OR</span>
                </div>
              </Paragraph>
              {roleRequestDetails.status === REQUEST_STATUS.IN_PROGRESS
                ? declineForwardedRequestSection
                : markForRecruitmentSection}
              )
            </div>
          </>
        )}
      </>
    );

  useEffect(() => {
    const loggedInuser = cookies.get("loggedInuser");

    if (loggedInuser) {
      setIsLoggedInUserDeputy(
        loggedInuser.applicationRoles.find((ar) => ar === ROLES.DEPUTY)
      );
    }

    if (CheckUserRoleService.checkRole()) {
      fetchRoleRequestDetails();

      const token = PubSub.subscribe(Events.TL_REQUEST_STATUS_UPDATED, () => {
        fetchRoleRequestDetails();
      });

      return () => {
        PubSub.unsubscribe(token);
      };
    }
  }, [id]);

  useEffect(() => {
    const isRequestedPathMatchingRole: boolean =
      CheckUserRoleService.checkRole();

    if (isRequestedPathMatchingRole) {
      fetchTeamMembers()
        .unwrap()
        .then((teamMembers: Employee[]) => {
          const separatedTeamMembersVacancies = _.partition(
            teamMembers,
            (teamMember: Employee) => teamMember.lastName === "Vacancy"
          );

          setVacancies(separatedTeamMembersVacancies[0]);
          setTeamMembers(separatedTeamMembersVacancies[1]);
        })
        .catch(() => console.error("Failed to fetch team members"));
    }
  }, []);

  useEffect(() => {
    const isRequestedPathMatchingRole: boolean =
      CheckUserRoleService.checkRole();

    if (isRequestedPathMatchingRole && roleRequestDetails.genericRole) {
      fetchTeamLeads()
        .unwrap()
        .then((leads: Employee[]) => setTeamLeads(leads))
        .catch(() => console.error("Failed to fetch team leads"));
    }
  }, [roleRequestDetails]);

  /**
   * If the role-request is PROPOSED, CONFIRMED or IN_PROGRESS, and an update occurs then we want to
   * automatically confirm the update when the page loads.
   */
  useEffect(() => {
    async function handleAcceptUpdate() {
      await onAcceptUpdatedRequest(false);
    }

    if (shouldAutoAcceptUpdate()) {
      handleAcceptUpdate();
    }
  }, [roleRequestDetails]);

  async function fetchRoleRequestDetails() {
    setLoading(true);

    fetchTeamLeadRequestDetails(parseInt(id))
      .unwrap()
      .then((requestDetails: RequestOverviewDetails) => {
        if (requestDetails) {
          setRoleRequestDetails(requestDetails);

          const childRequest =
            requestDetails.childRequest?.roleAllocationDetails.length > 0
              ? requestDetails.childRequest.roleAllocationDetails
              : null;

          setAllocationDetailsData({
            ...allocationDetailsData,
            transformedAllocations: transformYearMonth(
              requestDetails.roleAllocationDetails,
              "requiredPercentage"
            ),
            proposedTransformedAllocations: transformYearMonth(
              requestDetails.roleAllocationDetails,
              "proposedPercentage"
            ),
            transformedEditedAllocations: childRequest
              ? transformYearMonth(childRequest, "requiredPercentage")
              : [],
            originalAllocations:
              childRequest || requestDetails.roleAllocationDetails,
          });

          setInRecruitment(
            requestDetails.status === REQUEST_STATUS.IN_RECRUITMENT
          );
          const isOutdated = childRequest
            ? childRequest.isOutdated
            : requestDetails.isOutdated;
          setIsOutdated(isOutdated);
        }
      })
      .catch(() => {
        console.error("Failed to get TeamLeadRequestDetails list");
      });

    setLoading(false);
  }

  /**
   * Determines whether an update should be automatically accepted based on the status of the role request.
   * @returns {boolean} Returns true if the update should be auto-accepted, false otherwise.
   */
  function shouldAutoAcceptUpdate(): boolean {
    const isNoActionRequest: boolean = [
      REQUEST_STATUS.PROPOSED,
      REQUEST_STATUS.CONFIRMED,
      REQUEST_STATUS.IN_PROGRESS,
    ].includes(roleRequestDetails?.status);

    return (
      roleRequestDetails?.status === roleRequestDetails?.childRequest?.status &&
      isNoActionRequest
    );
  }

  function onSelectedMemberChange(selectedMemberName: string, object): void {
    setSelectedTeamMemberFullName(selectedMemberName);
    setSelectedMemberId(object?.key);
  }

  function onSelectedTeamLeadChange(
    forwardedTeamLeadName: string,
    object
  ): void {
    setSelectedForwardTeamLeadFullName(forwardedTeamLeadName);
    setSelectedTeamLeadId(object?.key);
  }

  function onSelectedVacancyChange(selectedVacancyName: string, object): void {
    setSelectedVacancyName(selectedVacancyName);
    setSelectedVacancyId(object?.key);
  }

  function switchNameAndSurname(
    fullNameToSwitchNameAndSurname: string
  ): string {
    if (!fullNameToSwitchNameAndSurname) return;

    const name: string = fullNameToSwitchNameAndSurname.substring(
      fullNameToSwitchNameAndSurname.indexOf(",") + 1,
      fullNameToSwitchNameAndSurname.length
    );

    const surname: string = fullNameToSwitchNameAndSurname.substring(
      0,
      fullNameToSwitchNameAndSurname.indexOf(",")
    );

    return `${name} ${surname}`;
  }

  function handoverAlert(): ReactElement {
    if (!roleRequestDetails.isHandedOver) return null;

    return (
      <Alert
        className="info-alert"
        message="This request has been handed over to you from another team lead."
        type="info"
        showIcon
      />
    );
  }

  function TeamLeadMessage(): ReactElement {
    let message: ReactElement;
    const requestStatus = roleRequestDetails.status;
    const proposedByPrimaryTeamLead =
      roleRequestDetails.primaryTeamLeadEmployeeId ===
      roleRequestDetails.currentAssignedTeamLeadId;
    const isPrimaryTeamLead = employeeData.isPrimary(
      roleRequestDetails.primaryTeamLeadEmployeeId
    );

    switch (requestStatus) {
      case REQUEST_STATUS.IN_PROGRESS:
        message = (
          <p data-testid="team-lead-info">
            This request has been forwarded to{" "}
            <b>{roleRequestDetails.currentAssignedTeamLeadName}</b>. No further
            action is required right now.
          </p>
        );
        break;
      case REQUEST_STATUS.PROPOSED:
        message = (
          <p data-testid="team-lead-info">
            <b>{roleRequestDetails.assignedTeamMember}</b> has been proposed. No
            further action is required right now.
          </p>
        );
        if (isPrimaryTeamLead && !proposedByPrimaryTeamLead)
          message = (
            <p data-testid="team-lead-info">
              <b>{roleRequestDetails.currentAssignedTeamLeadName}</b> has
              proposed <b>{roleRequestDetails.assignedTeamMember}</b>. No
              further action is required right now.
            </p>
          );
        break;
      case REQUEST_STATUS.CONFIRMED:
        message = (
          <p data-testid="team-lead-info">
            This role has already been staffed with{" "}
            <b>{roleRequestDetails.assignedTeamMember}</b>. No further action is
            required right now.
          </p>
        );

        if (isPrimaryTeamLead && !proposedByPrimaryTeamLead)
          message = (
            <p data-testid="team-lead-info">
              This role has already been staffed with{" "}
              <b>{roleRequestDetails.assignedTeamMember}</b> proposed by{" "}
              <b>{roleRequestDetails.currentAssignedTeamLeadName}</b>. No
              further action is required right now.
            </p>
          );
        break;
      case REQUEST_STATUS.DECLINED:
        message = (
          <p data-testid="team-lead-info">
            You declined this request. Currently, the Project Lead needs to
            react on your feedback
          </p>
        );
        break;
      default:
    }

    return (
      <Paragraph className="request-in-progress">
        <h2>Request status: {requestStatus}</h2>
        {message}
      </Paragraph>
    );
  }

  async function onPropose(
    selectedId: number,
    messageText: string
  ): Promise<void> {
    setLoading(true);

    try {
      await proposeTeamMember({
        roleRequestId: parseInt(id),
        teamMemberId: selectedId,
      }).unwrap();
      await navigate(ROUTES.TEAM_LEAD.STAFFING_REQUESTS);
      getSuccessMessage(`${messageText} proposed successfully`);
    } catch (error) {
      console.error("Failed to propose team member", error);
    }

    setLoading(false);
  }

  async function onProposeTeamMember(): Promise<void> {
    await onPropose(selectedMemberId, "Team Member");
  }

  async function onProposeVacancy(): Promise<void> {
    await onPropose(selectedVacancyId, "Vacancy");
  }

  async function forwardRequestToTeamLead(): Promise<void> {
    setLoading(true);

    try {
      await forwardRequest({
        roleRequestId: parseInt(id),
        teamLeadId: selectedTeamLeadId,
      }).unwrap();

      await navigate(ROUTES.TEAM_LEAD.STAFFING_REQUESTS);
      getSuccessMessage("Request forwarded successfully");
    } catch (error) {
      console.error("Failed to forward request", error);
    }

    setLoading(false);
  }

  async function saveOnInRecruitmentChange(): Promise<void> {
    setLoading(true);

    try {
      await updateInRecruitment({
        roleRequestId: parseInt(id),
        inRecruitment: inRecruitmentChecked,
      }).unwrap();

      setRoleRequestDetails({ ...roleRequestDetails, isDeclined: false });
      getSuccessMessage(
        `Request ${
          inRecruitmentChecked ? "marked" : "un-marked"
        } 'In Recruitment' successfully`
      );
    } catch (error) {
      console.error("Failed to update in recruitment", error);
    }

    setLoading(false);
  }

  async function onTakeBackProposal(): Promise<void> {
    setLoading(true);

    try {
      await takeBackProposal({ roleRequestId: parseInt(id) }).unwrap();
      getSuccessMessage("Proposal taken back successfully");
    } catch (error) {
      console.error("Failed to take back proposal", error);
    }

    setLoading(false);
  }

  async function declineForwardedRequest(): Promise<void> {
    setLoading(true);
    declineRequest({
      payLoad: { id: parseInt(id), requestAllocationPayload: [] },
      params: { declineRequestType: "forwarded" },
    })
      .unwrap()
      .then(() => {
        navigate(ROUTES.TEAM_LEAD.STAFFING_REQUESTS);

        getSuccessMessage("Request declined successfully!!");
      })
      .catch(() => {
        console.error("Failed to decline the request");
      });
    setLoading(false);
  }

  function getSuccessMessage(successMessage: string): void {
    PubSub.publish(Events.TL_REQUEST_STATUS_UPDATED);

    message.success({
      content: successMessage,
    });
  }

  function getColumnRenderer(text: string, record, fieldName: string) {
    let state = "";
    let value: string = text;

    if (
      roleRequestDetails.childRequest &&
      roleRequestDetails.childRequest[fieldName] !== record[fieldName]
    ) {
      if (roleRequestDetails.childRequest[fieldName]) {
        state = "modified";
        value = roleRequestDetails.childRequest[fieldName];
      } else {
        state = "deleted";
        value = text;
      }
    }

    return <span className={`state-${state}`}>{value}</span>;
  }

  async function onAcceptUpdatedRequest(navigateBack?: boolean): Promise<void> {
    try {
      acceptUpdatedRequest({
        roleRequestId: parseInt(id),
      }).unwrap();

      if (navigateBack) {
        navigateBack && (await navigate(ROUTES.TEAM_LEAD.STAFFING_REQUESTS));
        getSuccessMessage("Request update accepted successfully");
      }
    } catch (error) {
      console.error("Failed to accept the request", error);
    }
  }

  async function setProposedRequestToRequested(): Promise<void> {
    try {
      await revokeUpdatedRequest({ roleRequestId: parseInt(id) }).unwrap();
      getSuccessMessage("Request update revoked and set to requested state");
    } catch (error) {
      console.error("Failed to revoke the request", error);
    }
  }

  function showActions(): ReactElement {
    const isTeamLeadActionsDisabled: boolean = areTeamLeadActionsDisabled();

    // checks if the request is not declined and if child is available then checks if the child request is not declined
    const isStatusNotDeclined: boolean =
      (roleRequestDetails.status !== REQUEST_STATUS.DECLINED &&
        !roleRequestDetails.childRequest) ||
      (roleRequestDetails.childRequest &&
        roleRequestDetails.childRequest.status === REQUEST_STATUS.REQUESTED);

    const canAutoAcceptUpdate: boolean = shouldAutoAcceptUpdate();

    if (!isTeamLeadActionsDisabled) return displayTeamLeadActions;

    if (isStatusNotDeclined && !canAutoAcceptUpdate) {
      return initialResponsePrompt;
    }
  }

  function isRequestForwardedToAnother(): boolean {
    return (
      employeeData.isPrimary(roleRequestDetails.primaryTeamLeadEmployeeId) &&
      (roleRequestDetails.status === REQUEST_STATUS.IN_PROGRESS ||
        (roleRequestDetails.childRequest &&
          roleRequestDetails.childRequest.status ===
            REQUEST_STATUS.IN_PROGRESS))
    );
  }

  function isRequestHandedOverToSecondaryTL(): boolean {
    return (
      roleRequestDetails.isCurrent &&
      employeeData.isPrimary(roleRequestDetails.primaryTeamLeadEmployeeId) &&
      (roleRequestDetails.status === REQUEST_STATUS.IN_PROGRESS ||
        (roleRequestDetails.childRequest &&
          roleRequestDetails.childRequest.status ===
            REQUEST_STATUS.IN_PROGRESS))
    );
  }

  function areTeamLeadActionsDisabled(): boolean {
    if (roleRequestDetails.status === REQUEST_STATUS.DECLINED) {
      return true;
    }

    if (roleRequestDetails.childRequest && isRequestForwardedToAnother()) {
      return false;
    }

    return (
      roleRequestDetails.childRequest &&
      !(
        roleRequestDetails.status === REQUEST_STATUS.REQUESTED ||
        roleRequestDetails.status === REQUEST_STATUS.IN_PROGRESS
      )
    );
  }

  if (loading) {
    return (
      <div
        style={{
          margin: "auto",
          width: "150px",
        }}
      >
        <Spin tip="Please wait..." />
      </div>
    );
  } else
    return (
      <>
        {declineRequestWithCommentSection}
        <div className="suggest-team-member-page">
          <Button
            className="btn-back-navigation"
            onClick={() => navigate(ROUTES.TEAM_LEAD.STAFFING_REQUESTS)}
          >
            <ArrowLeftOutlined /> Return to request overview
          </Button>

          {((!isRequestHandedOverToSecondaryTL() &&
            isRequestForwardedToAnother()) ||
            roleRequestDetails.status === REQUEST_STATUS.PROPOSED ||
            roleRequestDetails.status === REQUEST_STATUS.CONFIRMED) &&
          !areTeamLeadActionsDisabled() ? (
            <div className="request-status">
              <Headline title="Request Details" />
              {handoverAlert()}
              {TeamLeadMessage()}
            </div>
          ) : (
            <div>{handoverAlert()}</div>
          )}

          {shouldAutoAcceptUpdate() && TeamLeadMessage()}

          {(isLoggedInUserDeputy ||
            (roleRequestDetails.currentAssignedTeamLeadId === employeeData.id &&
              !areTeamLeadActionsDisabled())) &&
            (roleRequestDetails.status === REQUEST_STATUS.PROPOSED ||
              roleRequestDetails.status === REQUEST_STATUS.CONFIRMED) &&
            !roleRequestDetails.isOutdated && (
              <div
                className="request-status"
                data-testid="takeBackProposalSection"
              >
                <Paragraph className="request-in-progress">
                  <h2 data-testid="takeBackInfoMsg">
                    Do you want to take back your proposed team member?
                  </h2>
                  <p data-testid="takeBackInfoMsg2">
                    You can replace proposed team member in next step
                  </p>
                  <Button
                    onClick={() => setModalVisible(true)}
                    data-testid="takeBackProposalBtn"
                    type="primary"
                  >
                    Take back proposal
                  </Button>
                </Paragraph>

                <NoteModal
                  action={onTakeBackProposal}
                  setModalVisible={setModalVisible}
                  modalVisible={modalVisible}
                  requestId={parseInt(id)}
                  isProjectLead={false}
                  isNoteMandatory={false}
                  modalMsg={{
                    action: "Take back proposal",
                    title: "Take back proposal",
                    msg: (
                      <span>
                        Are you sure you want to take back{" "}
                        <b>
                          {switchNameAndSurname(
                            roleRequestDetails.assignedTeamMember
                          )}
                        </b>
                        ?
                      </span>
                    ),
                    leaveCommentFor: switchNameAndSurname(
                      roleRequestDetails.projectLead
                    ),
                    size: "large",
                  }}
                />
              </div>
            )}

          <div>
            {roleRequestDetails.isTlChangedOfAssignedEmployee && (
              <Alert
                className="state-modified-alert"
                message="The already confirmed team member assignment to this project request has been set back to
                the 'requested' status due to changes in team set-ups and team lead responsibilities."
                type="info"
                showIcon
              />
            )}
            {roleRequestDetails.childRequest &&
              !isRequestForwardedToAnother() && (
                <Alert
                  className="info-alert"
                  message={`This request has been changed. ${
                    shouldAutoAcceptUpdate() ||
                    roleRequestDetails.isOnlyFreeTextFieldChanged
                      ? "The update has been automatically accepted."
                      : "Please review the changes and accept them."
                  }`}
                  type="info"
                  showIcon
                />
              )}
            {roleRequestDetails.isDeclined &&
              !isRequestForwardedToAnother() && (
                <Alert
                  className="state-modified-alert"
                  message="PROPOSAL DECLINED - please respond to this request again."
                  type="info"
                  showIcon
                />
              )}
            {roleRequestDetails.isEmployeeDeleted && (
              <Alert
                className="state-modified-alert"
                message="EMPLOYEE DELETED - the formerly assigned employee has been deleted and the request was set back to the status requested."
                type="info"
                showIcon
              />
            )}
            {isOutdated && (
              <Alert
                className="state-modified-alert"
                message="This request is outdated, please get in contact with the project lead."
                type="info"
                showIcon
              />
            )}

            <div className="request-details">
              <Paragraph className="request-id">
                <label>REQUEST -</label>
                <i> {roleRequestDetails.projectRoleRequestId}</i>
              </Paragraph>
              <Paragraph data-testid="projectId">
                <label>Project ID:</label>
                <i> {roleRequestDetails.projectId}</i>
              </Paragraph>
              <Paragraph data-testid="projectNameLabel">
                <label>Project name:</label>
                <i> {roleRequestDetails.projectName}</i>
              </Paragraph>
              <Paragraph data-testid="countryNameLabel">
                <label>Country:</label>
                <i> {roleRequestDetails.country}</i>
              </Paragraph>
              <Paragraph data-testid="projectTypeLabel">
                <label>Project Type:</label>
                <i> {roleRequestDetails.projectType}</i>
              </Paragraph>
              <Paragraph data-testid="stageGateDateLabel">
                <label>Next stage gate date (estimated):</label>
                <i>
                  {" "}
                  {roleRequestDetails.stageGateDate
                    ? moment(roleRequestDetails.stageGateDate).format(
                        "Do MMMM YYYY"
                      )
                    : "No stage gate date set"}
                </i>
              </Paragraph>
              <Paragraph data-testid="requestNumberLabel">
                <label>Request number:</label>
                <i>
                  {" "}
                  {RequestIdTransformation(id, roleRequestDetails.projectName)}
                </i>
              </Paragraph>
              <Paragraph data-testid="projectIdentifierLabel">
                <label>Project identifier:</label>
                <i>
                  {" "}
                  {getColumnRenderer(
                    roleRequestDetails.projectIdentifier,
                    roleRequestDetails,
                    "projectIdentifier"
                  )}
                </i>
              </Paragraph>
              <Paragraph data-testid="projectManagerLabel">
                <label>Project manager:</label>
                <i> {roleRequestDetails.projectLead}</i>
              </Paragraph>
              <Paragraph data-testid="projectTechnologyLabel">
                <label>Technology:</label>
                <i>
                  {" "}
                  {roleRequestDetails.technologies
                    ? roleRequestDetails.technologies
                    : "No entry"}
                </i>
              </Paragraph>

              {roleRequestDetails.sendBy && (
                <Paragraph className="send-by">
                  <label>Send by:</label>
                  <i> {roleRequestDetails.sendBy.fullName}</i>
                </Paragraph>
              )}

              <Paragraph data-testid="commentSection">
                <Table
                  data-testid="commentTable"
                  dataSource={[roleRequestDetails]}
                  rowKey="id"
                  columns={[
                    {
                      title: "Comment",
                      dataIndex: "commentSection",
                      key: "commentSection",
                      render: () => {
                        return (
                          <>
                            <NoteHistory requestId={parseInt(id)} />
                            {isOutdated &&
                              roleRequestDetails.status !== "Confirmed" && (
                                <NoteForm
                                  requestId={parseInt(id)}
                                  action={() => window.location.reload()}
                                  setModalVisible={setModalVisible}
                                  modalMsg={{
                                    title: "",
                                    msg: "",
                                    action: "Send",
                                    size: "small",
                                  }}
                                  isNoteMandatory={false}
                                  isActionButtonDisabled={false}
                                />
                              )}
                          </>
                        );
                      },
                    },
                  ]}
                  scroll={{}}
                  size="small"
                  pagination={false}
                />
              </Paragraph>

              <Paragraph data-testid="requestInformationSection">
                <Table
                  dataSource={[roleRequestDetails]}
                  rowKey="id"
                  columns={columns}
                  scroll={{}}
                  pagination={false}
                  size="small"
                />
              </Paragraph>
              <Paragraph data-testid="scopeDescriptionSection">
                <Table
                  dataSource={[roleRequestDetails]}
                  rowKey="id"
                  columns={[
                    {
                      title: "Scope Description",
                      dataIndex: "scopeDescription",
                      key: "scopeDescription",
                      render: (
                        text: string,
                        record: RequestOverviewDetails
                      ) => {
                        return getColumnRenderer(
                          text,
                          record,
                          "scopeDescription"
                        );
                      },
                    },
                  ]}
                  scroll={{}}
                  size="small"
                  pagination={false}
                />
              </Paragraph>

              {!isOutdated && (
                <Paragraph>
                  <div className="requestedTime">
                    <AllocationDetailsTable requestData={roleRequestDetails} />
                  </div>
                </Paragraph>
              )}

              {((!employeeData.isPrimary(
                roleRequestDetails.primaryTeamLeadEmployeeId
              ) &&
                roleRequestDetails.status === REQUEST_STATUS.IN_PROGRESS) ||
                roleRequestDetails.status === REQUEST_STATUS.REQUESTED) &&
                !isOutdated && (
                  <Paragraph>
                    <TeamMemberCapacities
                      ref={childRef}
                      roleRequestId={id}
                      genericRole={roleRequestDetails.genericRole}
                      childRequest={
                        roleRequestDetails.childRequest
                          ? roleRequestDetails.childRequest
                              .roleAllocationDetails
                          : null
                      }
                    />
                  </Paragraph>
                )}
            </div>
            {showActions()}
          </div>
        </div>
      </>
    );
};

export default TeamLeadSuggestTeamMemberPage;
